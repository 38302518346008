<template>
  <div>
    <b-sidebar
      id="sidebar-footer_group"
      aria-label="Sidebar with custom footer"
      no-header
      shadow
      backdrop
      width="17%"
      v-model="groupList"
    >
      <template #footer>
        <div
          class="d-flex text-light align-items-center px-3 py-2"
          style="background: #2c3e50"
        >
          <strong class="mr-auto" v-b-modal.new_group>新建群组</strong>
          <b-button size="sm" @click="hideBothWindow()">取消</b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <div v-if="allGroupList.length === 0">--- 无结果 ---</div>

        <b-list-group v-else style="max-width: 480px">
          <b-list-group-item
            v-for="i in allGroupList"
            :key="i.id"
            class="d-flex align-items-center"
            @click="onRecentGroup(i)"
            style="cursor: pointer"
          >
            <b-avatar v-bind:src="i.avatar" class="mr-3"></b-avatar>
            <span class="mr-auto">{{ i.name }}</span>
            <!-- <b-badge v-if="i.count > 0">{{ i.count }}</b-badge> -->
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-sidebar>
    <!-- ======================================== Create a New group Modal ======================================== -->
    <b-modal
      id="new_group"
      ref="modal"
      title="设置群聊名称"
      @hidden="resetModalNewGroup"
    >
      <form ref="form" @submit.stop.prevent="handleSubmitNewGroup">
        <b-form-group
          label="群聊名称："
          label-for="name-input"
          invalid-feedback="必须输入群主名"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="groupname"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" @click="cancel()"> 取消 </b-button>
        <b-button size="sm" variant="primary" @click="createNewGroup()">
          确认
        </b-button>
      </template>
    </b-modal>

    <!-- ================================ Add member now or later confirmation Modal ============================================ -->

    <b-modal v-model="modalShowAddAgent" title="邀人">
      <p class="my-4">是否要邀人进群或稍后?</p>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" @click="cancel()"> 稍后 </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="
            addMoreMembers();
            addGroupMembersModal = true;
            modalShowAddAgent = false;
          "
        >
          现在
        </b-button>
      </template>
    </b-modal>

    <!-- ========================================= Add member Modal ============================================ -->

    <b-modal v-model="addGroupMembersModal" scrollable size="xl">
      <template #modal-header>
        <h5>添加会员</h5>
      </template>

      <template #default>
        <b-row>
          <b-col cols="5">
            <b-form inline>
              <label class="mr-sm-4">搜索单个用户:</label>
              <b-form-input
                v-model="query"
                aria-label="First name"
              ></b-form-input>
            </b-form>
          </b-col>
          <b-col cols="6">
            <b-form inline>
              <label class="mr-sm-4">选择代理:</label>
              <b-form-select
                v-model="selectedAgent"
                :options="allAgentList"
                text-field="name"
                value-field="id"
                style="width: 50%"
              >
                <template #first>
                  <b-form-select-option :value="0"
                    >所有客服</b-form-select-option
                  >
                </template>
                <!-- <b-form-select-option value="a">Option A</b-form-select-option> -->
              </b-form-select>
            </b-form>
          </b-col>
          <b-col cols="1">
            <b-button variant="primary" class="mb-2" @click="smartSearch">
              <b-icon icon="search" aria-label="Help"></b-icon>
            </b-button>
          </b-col>
        </b-row>
        <b-row
          ><b-col cols="12"
            ><b-form-checkbox v-model="selectAll" @change="selectAllMembers"
              >选择全部</b-form-checkbox
            ></b-col
          ></b-row
        >
        <b-modal v-model="selectAllModal" centered title="确认">
          <p class="my-4">您确认添加所有成员？</p>
          <template #modal-footer="{ cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button
              size="sm"
              @click="
                cancel();
                selectAll = false;
              "
            >
              取消
            </b-button>
            <b-button
              size="sm"
              variant="primary"
              @click="
                addUserToGroup('', 1, selectedAgent, query);
                selectAllModal = false;
                selectAll = false;
                addGroupMembersModal = false;
              "
            >
              确认
            </b-button>
          </template>
        </b-modal>
        <b-row style="max-height: 665px; overflow-y: auto; padding-top: 15px">
          <b-table-simple :current-page="currentPage" responsive>
            <b-tbody>
              <b-tr v-for="i in groupMemberList" :key="i.id">
                <b-td style="width: 23%; padding-left: 0px">
                  <b-avatar v-bind:src="i.avatar"></b-avatar
                ></b-td>
                <b-td style="width: 24%; line-height: 40px">{{
                  i.name === "" ? "佚名" : i.name
                }}</b-td>
                <b-td style="width: 24%; line-height: 40px">{{
                  i.account
                }}</b-td>
                <b-td style="width: 23%; line-height: 40px">{{
                  i.telephone
                }}</b-td>
                <b-td
                  style="text-right; padding-right: 25px; padding-top: 15px;"
                  ><b-form-checkbox
                    v-model="i.selected"
                    @change="onUserAddListCheckbox(i)"
                  ></b-form-checkbox
                ></b-td>
              </b-tr>
              <b-tr v-if="groupMemberList.length == 0"
                ><b-td>
                  <p style="text-align: center; margin: 30px">无结果</p></b-td
                ></b-tr
              >
            </b-tbody>
          </b-table-simple>
          <b-pagination
            v-if="total_rows > 1"
            style="position: absolute; bottom: -60px; width: 200px"
            v-model="currentPage"
            :total-rows="total_rows"
            aria-controls="member_table"
            @change="pageChangeSmartSearch"
          ></b-pagination>
        </b-row>
      </template>

      <template #modal-footer="{ cancel, ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="cancel()"> 取消 </b-button>
        <b-button size="sm" variant="primary" @click="ok()"> 确认 </b-button>
      </template>
    </b-modal>

    <!-- ======================================================================================================== -->
    <!-- ============================================= Chat panel of group chat ================================= -->

    <b-sidebar right shadow width="83%" no-header v-model="groupChatWindow">
      <b-overlay
        :show="isShowLoader"
        no-wrap
        style="z-index: 20000"
      ></b-overlay>
      <b-col col lg="12" class="pannelBackground">
        <b-row style="background: #435f7a; height: 50px">
          <b-col col lg="6">
            <p class="chatGroupname">{{ selectedGroup.name }}</p>
          </b-col>
          <b-col cols="6" style="padding-top: 5px">
            <b-button-toolbar style="float: right">
              <b-button-group class="mr-1">
                <!-- ============================ Buttons for show group members, add or remove group members ============================== -->

                <b-button
                  @click="showGroupMembers()"
                  style="background: #2c3e50"
                >
                  <b-icon
                    icon="person-lines-fill"
                    aria-hidden="true"
                    style="line-height: 50px; color: #ffffff"
                  ></b-icon>
                </b-button>

                <!-- ============================ Buttons for add or remove members ============================== -->

                <b-button @click="addMoreMembers()" style="background: #2c3e50">
                  <b-icon
                    icon="person-plus"
                    aria-hidden="true"
                    style="line-height: 50px; color: #ffffff"
                  ></b-icon>
                </b-button>

                <!-- ============================ Buttons for rename group ============================== -->

                <b-button v-b-modal.rename_group style="background: #2c3e50">
                  <b-icon
                    icon="pencil-square"
                    aria-hidden="true"
                    style="line-height: 50px; color: #ffffff"
                  ></b-icon>
                </b-button>

                <!-- ============================ Buttons for aload history ============================== -->

                <b-button
                  @click="
                    keyWordHistory = '';
                    loadGroupHistory();
                  "
                  style="background: #2c3e50"
                >
                  <b-icon
                    icon="clock-history"
                    aria-hidden="true"
                    style="line-height: 50px; color: #ffffff"
                  ></b-icon>
                </b-button>

                <!-- ============================ Buttons for delete a group ============================== -->

                <b-button v-b-modal.deleteGroup style="background: #2c3e50">
                  <b-icon
                    icon="trash"
                    aria-hidden="true"
                    style="line-height: 50px; color: #ffffff"
                  ></b-icon> </b-button
              ></b-button-group>
            </b-button-toolbar>

            <!-- =================================== show member list Modal ======================== -->

            <b-modal v-model="showGroupMembersModal" size="xl">
              <template #modal-header>
                <h5>会员列表</h5>
              </template>

              <template #default>
                <b-row>
                  <b-col cols="6">
                    <b-form inline>
                      <label class="mr-sm-4">搜索用户:</label>
                      <b-form-input
                        aria-label="user"
                        v-model="memberListQuery"
                      ></b-form-input>
                      <b-button
                        @click="onMemberListSearch"
                        variant="primary"
                        style="margin-left: 10px"
                      >
                        <b-icon icon="search" aria-label="username"></b-icon>
                      </b-button>
                    </b-form>
                  </b-col>
                  <b-col cols="6" style="text-align: right">
                    <div style="padding-right: 15px">
                      <b-button variant="primary" v-b-modal.modalRemoveAll
                        >移除所有</b-button
                      >
                    </div>
                  </b-col>
                  <b-modal id="modalRemoveAll" centered title="确认">
                    <p>您确认移除所有成员？</p>
                    <template #modal-footer="{ cancel }">
                      <b-button size="sm" @click="cancel()"> 取消 </b-button>
                      <b-button
                        size="sm"
                        variant="primary"
                        @click="removeAllGroupMembers()"
                      >
                        确认
                      </b-button>
                    </template>
                  </b-modal>
                </b-row>
                <b-row
                  style="height: 700px; overflow-y: auto; padding-top: 15px"
                >
                  <b-table-simple
                    id="member_table"
                    :current-page="currentPage"
                    responsive
                  >
                    <b-tbody>
                      <b-tr v-for="i in allGroupMembers" :key="i.id">
                        <b-td style="width: 23%; padding-left: 0px">
                          <b-avatar v-bind:src="i.avatar"></b-avatar
                        ></b-td>
                        <b-td style="width: 24%; line-height: 40px">{{
                          i.name === "" ? "佚名" : i.name
                        }}</b-td>
                        <b-td style="width: 24%; line-height: 40px">{{
                          i.account
                        }}</b-td>
                        <b-td style="width: 23%; line-height: 40px">{{
                          i.telephone
                        }}</b-td>
                        <b-td
                          style="text-right; padding-right: 25px; padding-top: 15px;"
                          ><b-form-checkbox
                            v-model="i.selected"
                            value="true"
                            @change="onUserAddListCheckbox(i)"
                          ></b-form-checkbox
                        ></b-td>
                      </b-tr>
                      <b-overlay
                        :show="isShowLoaderPagination"
                        no-wrap
                        style="z-index: 20000"
                      ></b-overlay>
                    </b-tbody>
                  </b-table-simple>
                  <p
                    v-if="allGroupMembers.length == 0"
                    style="text-align: center"
                  >
                    无结果
                  </p>
                  <b-pagination
                    style="position: absolute; bottom: -60px; width: 200px"
                    v-model="currentPage"
                    :total-rows="total_rows"
                    aria-controls="member_table"
                    @change="pageChange"
                  ></b-pagination>
                </b-row>
              </template>

              <template #modal-footer="{ cancel, ok }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" @click="cancel()"> 取消 </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                  确认
                </b-button>
              </template>
            </b-modal>

            <!-- ================================= show history Modal ================================== -->

            <b-modal v-model="groupHistoryModal" scrollable size="xl">
              <template #modal-header>
                <h5>聊天记录</h5>
              </template>

              <template #default>
                <b-row>
                  <b-col cols="7">
                    <b-form inline>
                      <label class="mr-sm-4">查找聊天记录:</label>
                      <b-form-input
                        aria-label="history"
                        v-model="keyWordHistory"
                      ></b-form-input>
                      <!-- -------------------------------- search by key word, same API ------------------------ -->
                      <b-button
                        variant="primary"
                        style="margin-left: 10px"
                        @click="loadGroupHistory()"
                      >
                        <b-icon icon="search" aria-label="message"></b-icon>
                      </b-button>
                    </b-form>
                  </b-col>
                  <b-col cols="5"> </b-col>
                </b-row>
                <b-row>
                  <div
                    class="chatWindowBodyHistory"
                    style="max-height: 700px; overflow-y: auto"
                  >
                    <ul class="chatWindowBodyUl">
                      <!-- ---------------------------------- history message UI section ------------------------------------>
                      <li
                        v-for="(item, index) in historyDataGroup"
                        :key="index"
                      >
                        <div class="sent">
                          <img
                            class="imageright"
                            src="https://image.chatcsdomain.com/group.jpeg"
                          />
                          <div class="talktext">
                            <p
                              v-if="item.format == 0"
                              v-html="
                                processContent(
                                  item.content.replace(
                                    /(?:\r\n|\r|\n)/g,
                                    '<br />'
                                  )
                                )
                              "
                            ></p>
                            <b-img
                              v-if="item.format == 1"
                              :src="
                                $baseStoragePath +
                                JSON.parse(item.content).image
                              "
                              @click="
                                showImg(
                                  $baseStoragePath +
                                    JSON.parse(item.content).image
                                )
                              "
                              style="
                                width: auto;
                                height: 200px;
                                max-width: 600px;
                              "
                            ></b-img>
                            <!-- ================================= voice record messages ========================= -->
                            <audio
                              controls
                              v-if="item.format == 2"
                              :src="
                                $baseStoragePath + JSON.parse(item.content).file
                              "
                              type="audio/mpeg"
                            ></audio>
                            <b-embed
                              v-if="item.format == 8"
                              type="video"
                              aspect="4by3"
                              controls
                            >
                              <source
                                :src="
                                  $baseStoragePath +
                                  JSON.parse(item.content).video
                                "
                                type="video/mp4"
                              />
                            </b-embed>
                            <div
                              v-if="item.format == 3"
                              @click="onFileGroup(item.content)"
                            >
                              <img
                                v-bind:src="getFileTypeGroup(item.content)"
                                style="width: auto; height: 100px"
                              />
                              <p
                                style="
                                  text-align: right;
                                  color: #ced4da;
                                  font-size: 13px;
                                "
                              >
                                {{ JSON.parse(item.content).name }}
                              </p>
                            </div>
                            <p
                              style="
                                text-align: right;
                                color: #ced4da;
                                font-size: 13px;
                              "
                            >
                              {{ getTime(item.datetime) }}
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <p
                      v-if="historyDataGroup.length == 0"
                      style="text-align: center; margin-top: 100px"
                    >
                      无结果
                    </p>
                    <div
                      class="chatHistory"
                      id="groupChatLog"
                      v-if="loadMoreGroupBtn"
                    >
                      <b-button
                        style="background: #2c3e50"
                        @click="loadGroupHistory"
                      >
                        查看更多记录
                      </b-button>
                    </div>
                  </div></b-row
                >
              </template>

              <template #modal-footer="{ cancel, ok }">
                <b-button size="sm" @click="cancel()"> 取消 </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                  确认
                </b-button>
              </template>
            </b-modal>

            <!-- ====================================== Rename group name Modal ====================================== -->

            <b-modal
              id="rename_group"
              title="更新群名"
              @hidden="resetModalNewGroupName"
            >
              <form ref="form" @submit.stop.prevent="handleSubmitNewGroupName">
                <b-form-group
                  label="新的群名:"
                  label-for="name-input"
                  invalid-feedback="必须输入群主名"
                  :state="nameStateGroup"
                >
                  <b-form-input
                    id="name-input"
                    v-model="renamegroupname"
                    :state="nameStateGroup"
                    required
                  ></b-form-input>
                </b-form-group>
              </form>
              <template #modal-footer="{ cancel }">
                <b-button size="sm" @click="cancel()"> 取消 </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="handleOkRenameGroupName()"
                >
                  确认
                </b-button>
              </template>
            </b-modal>
            <!-- =========================================== Delete Group Modal ==================================== -->

            <b-modal id="deleteGroup" title="删除群组" centered>
              <p class="my-4">确定删除 {{ selectedGroup.name }}?</p>
              <template #modal-footer="{ cancel }">
                <b-button size="sm" @click="cancel()"> 取消 </b-button>
                <b-button size="sm" variant="primary" @click="deletedGroup()">
                  确认
                </b-button>
              </template>
            </b-modal>
          </b-col>
        </b-row>
        <!-- =============================== Send message with emoji, image, file, video ======================= -->

        <b-row>
          <b-col>
            <div style="padding: 190px 190px 0px">
              <b-form-textarea
                id="textarea-small"
                size="sm"
                placeholder="请输入内容"
                style="height: 500px"
                v-model="groupMessageContent"
              ></b-form-textarea>
            </div>
            <b-row style="padding: 25px 190px 0px">
              <!-- <b-col cols="10"></b-col> -->
              <b-col cols="8" style="text-align: left">
                <b-button-group>
                  <b-button id="showPopupEmoji" style="background: #2c3e50">
                    <b-icon icon="emoji-smile"></b-icon>
                  </b-button>
                  <b-button style="background: #2c3e50">
                    <input
                      id="fileUploadImageGroup"
                      type="file"
                      hidden
                      accept="image/*"
                      @change="onImageUploadGroup"
                    />
                    <b-icon
                      icon="card-image"
                      @click="chooseImageGroup()"
                    ></b-icon>
                  </b-button>
                  <b-button style="background: #2c3e50">
                    <input
                      id="fileUploadVideoGroup"
                      type="file"
                      accept="video/*"
                      hidden
                      @change="onVideoUploadGroup"
                    />
                    <b-icon
                      icon="camera-video"
                      @click="chooseVideoGroup()"
                    ></b-icon>
                  </b-button>
                  <b-button style="background: #2c3e50">
                    <input
                      id="fileUploadFileGroup"
                      type="file"
                      accept="file/*"
                      hidden
                      @change="onFileUploadGroup"
                    />
                    <b-icon icon="folder" @click="chooseFileGroup()"></b-icon>
                  </b-button>
                </b-button-group>

                <!-- ========= emoji popup ========= -->
                <b-popover
                  target="showPopupEmoji"
                  placement="topright"
                  :show.sync="emojiPopupShow"
                >
                  <ul class="emojiDesign">
                    <li
                      class="emojiListDesign"
                      v-for="(item, index) in emojiIcons"
                      :key="index"
                      @click="onEmoji(item)"
                    >
                      <b-img :src="item.text" />
                    </li>
                  </ul>
                </b-popover>
              </b-col>
              <b-col cols="4" style="text-align: right">
                <b-button
                  @click="sendMessageGroup(0, groupMessageContent)"
                  style="background: #2c3e50"
                >
                  发送 <b-icon icon="chevron-right"></b-icon> </b-button
              ></b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-sidebar>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import hevueImgPreview from "hevue-img-preview";
Vue.use(hevueImgPreview);

export default {
  name: "Groups",
  data() {
    return {
      groupname: "",
      nameState: null,
      modalShowAddAgent: false,
      addGroupMembersModal: false,
      selectedAgent: 0,
      showPopupEmoji: false,
      emojiPopupShow: false,
      isShowLoader: false,
      isShowLoaderPagination: false,
      emojiIcons: [
        {
          text: this.$baseURL + "/resource/layim/images/face/0.png",
          key: "[微笑]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/1.png",
          key: "[爱你]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/2.png",
          key: "[拜拜]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/3.png",
          key: "[悲伤]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/4.png",
          key: "[鄙视]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/5.png",
          key: "[闭嘴]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/6.png",
          key: "[馋嘴]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/7.png",
          key: "[吃惊]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/8.png",
          key: "[鼓掌]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/9.png",
          key: "[哈哈]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/10.png",
          key: "[害羞]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/11.png",
          key: "[黑线]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/12.png",
          key: "[可爱]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/13.png",
          key: "[可怜]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/14.png",
          key: "[亲亲]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/15.png",
          key: "[生病]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/16.png",
          key: "[偷笑]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/17.png",
          key: "[挖鼻]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/18.png",
          key: "[委屈]",
        },
      ],
      groupList: false,
      groupChatWindow: false,
      renamegroupname: "",
      nameStateGroup: null,
      allGroupList: "",
      selectedGroup: { name: "" },
      allAgentList: [],
      showGroupMembersModal: false,
      groupHistoryModal: false,
      allGroupMembers: "",
      // history
      loadMoreGroupBtn: false,
      historyPageNum: 0,
      historyDataGroup: [],
      historyDataTempGroup: [],
      keyWordHistory: "",

      //add memebers
      query: "",
      showMyUser: false,
      groupMemberList: [],
      selectAll: false,
      selectAllModal: false,
      memberListQuery: "",
      //table pagination
      currentPage: 1,
      total_rows: 0,

      //send message
      groupMessageContent: "",
    };
  },
  methods: {
    onRecentGroup(item) {
      this.groupChatWindow = true;
      this.selectedGroup = item;
      //set unread count to 0;
      item.count = 0;
      //reset paginator
      this.currentPage = 1;
      this.total_rows = 0;
    },

    // ============== Hide both windows =================

    hideBothWindow() {
      this.groupList = false;
      this.groupChatWindow = false;
    },

    // ===================  Hide emoji popup ======
    onEmoji(emoji) {
      this.showPopupEmoji = false;
      this.groupMessageContent += "face" + emoji.key;
      this.onCloseEmojiPopup();
    },

    onCloseEmojiPopup() {
      this.emojiPopupShow = false;
    },

    // =============== Add more members ========
    addMoreMembers() {
      this.groupMemberList = [];
      this.total_rows = 0;
      this.addGroupMembers();
      this.loadMyTeam();
    },

    // ================================================= Creat a group ================================================
    resetModalNewGroup() {
      this.groupname = "";
      this.nameState = null;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    createNewGroup() {
      let that = this;
      if (!this.checkFormValidity()) {
        return;
      }
      that
        .$http({
          url: "/api/group/create",
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {
            groupName: that.groupname,
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            that.loadAllGroups();
            // that.addGroupMembers(); // load agent list
            that.selectedGroup = data.data;
            this.$nextTick(() => {
              this.$bvModal.hide("new_group");
              this.modalShowAddAgent = true;
            });
          }
        });
    },
    //============================== load all group list ================
    loadAllGroups() {
      let that = this;
      that
        .$http({
          url: "/api/group/listNew",
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          if (res.data.code == 200) {
            that.allGroupList = res.data.dataList;
          }
        });
    },

    /**
     * load my team
     */
    loadMyTeam() {
      let that = this;
      that
        .$http({
          url: "/api/user/myTeam",
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          if (res.data.code == 200) {
            res.data.data.forEach((i) => {
              that.groupMemberList.push(i);
            });
          }
        });
    },
    // ======================= all group member modal functionality ===========

    showGroupMembers() {
      let that = this;
      this.isShowLoaderPagination = true;
      this.isShowLoader = true;
      that
        .$http({
          url:
            "/api/group/member/list/" +
            this.selectedGroup.id +
            "/" +
            (that.currentPage - 1), //current page minus 1 because framework paginate expecting first page to be 1, not 0
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.isShowLoaderPagination = false;
            this.isShowLoader = false;
            that.allGroupMembers = res.data.dataList;
            that.allGroupMembers.forEach((i) => {
              i.selected = true;
            });
            that.showGroupMembersModal = true;
            that.total_rows = res.data.page.count;
          } else {
            this.isShowLoaderPagination = false;
            this.isShowLoader = false;
          }
        })
        .finally(() => {
          this.isShowLoader = false;
          this.isShowLoaderPagination = false;
        });
    },

    // ======================= add group member modal functionality - get agent list===========

    addGroupMembers() {
      let that = this;
      this.isShowLoader = true;
      that
        .$http({
          url: "/api/user/agentList",
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.isShowLoader = false;
            // this.modalShowAddAgent = false;
            this.addGroupMembersModal = true;
            this.selectAll = false;
            that.allAgentList = res.data.data;
            that.allAgentList.forEach((i) => {
              that.groupMemberList.push(i);
            });
            // that.groupMemberList = that.allAgentList;
          } else {
            this.isShowLoader = false;
          }
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    /**
     * add members to group
     */
    addMemeberToGroup(member) {
      //TODO add group memebers from api
    },
    /**
     * smart search
     */
    smartSearch() {
      let that = this;
      that
        .$http({
          url:
            "api/user/smartSearch?groupId=" +
            that.selectedGroup.id +
            "&agentId=" +
            that.selectedAgent +
            "&showMyUsers=" +
            that.showMyUser +
            "&currentPage=" +
            (that.currentPage - 1) +
            "&keyWord=" +
            that.query,
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            // this.addGroupMembersModal = true;
            // this.modalShowAddAgent = false;
            that.groupMemberList = data.dataList;
            that.total_rows = res.data.page.count;
            // console.log(data.dataList);
          }
        });
    },
    // ======================= delete group functionality ===========

    deletedGroup() {
      let that = this;
      that
        .$http({
          url: "/api/group/block/" + this.selectedGroup.id,
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            that.loadAllGroups();
            this.groupChatWindow = false;
            this.$bvModal.msgBoxOk("成功移除群组", {
              title: "成功",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
            this.$bvModal.hide("deleteGroup");
          }
        });
    },
    // ===========  Rename group name
    checkFormValidityName() {
      const valid = this.$refs.form.checkValidity();
      this.nameStateGroup = valid;
      return valid;
    },

    resetModalNewGroupName() {
      this.renamegroupname = "";
      this.nameStateGroup = null;
    },

    handleOkRenameGroupName() {
      let that = this;
      if (!this.checkFormValidityName()) {
        return;
      }
      that
        .$http({
          url: "/api/group/changeName/" + this.selectedGroup.id,
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {
            groupName: that.renamegroupname,
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            that.loadAllGroups();
            that.selectedGroup.name = that.renamegroupname;
            this.$bvModal.msgBoxOk("成功更改群组名称", {
              title: "成功",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
            this.$bvModal.hide("rename_group");
          }
        });
    },
    // ===============================================================================================
    // ============================================= History =========================================
    // ===============================================================================================
    loadGroupHistory() {
      let that = this;
      let elmentLoadMoreGroup = document.getElementById("groupChatLog");
      if (!elmentLoadMoreGroup) {
        that.historyPageNum = 0;
      } else {
        that.historyPageNum++;
      }
      this.isShowLoader = true;
      that
        .$http({
          url:
            "/api/group/message/log/list/" +
            this.selectedGroup.id +
            "?currentPage=" +
            that.historyPageNum +
            "&keyword=" +
            that.keyWordHistory,
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.isShowLoader = false;
            this.groupHistoryModal = true;
            that.historyDataTempGroup = res.data.data.DataList;
            if (that.historyPageNum != 0) {
              that.historyDataGroup = that.historyDataGroup.concat(
                that.historyDataTempGroup
              );
            } else {
              this.isShowLoader = false;
              that.historyDataGroup = that.historyDataTempGroup;
            }
            //  Hide/show loadmore button
            if (that.historyDataTempGroup.length >= 20) {
              that.loadMoreGroupBtn = true;
            } else {
              that.loadMoreGroupBtn = false;
            }
          }
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    getTime(timestamp) {
      return moment(timestamp).format("MMMM Do YYYY, h:mm:ss a");
    },
    getFileTypeGroup(content) {
      let fileNameArray = JSON.parse(content).name.split(".");
      let fileType = fileNameArray[fileNameArray.length - 1];
      switch (fileType) {
        case "pdf":
          return require("../assets/img/chat_files/pdf.png");
        case "doc":
          return require("../assets/img/chat_files/doc.png");
        case "docx":
          return require("../assets/img/chat_files/doc.png");
        case "ppt":
          return require("../assets/img/chat_files/ppt.png");
        case "xls":
          return require("../assets/img/chat_files/xls.png");
        case "xlsx":
          return require("../assets/img/chat_files/xls.png");
        case "jpeg":
          return require("../assets/img/chat_files/jpeg.png");
        case "jpg":
          return require("../assets/img/chat_files/jpeg.png");
        case "mp3":
          return require("../assets/img/chat_files/mp3.png");
        case "png":
          return require("../assets/img/chat_files/png.png");
        case "rar":
          return require("../assets/img/chat_files/rar.png");
        case "txt":
          return require("../assets/img/chat_files/txt.png");
        case "zip":
          return require("../assets/img/chat_files/zip.png");
        case "gif":
          return require("../assets/img/chat_files/gif.png");
        default:
          return require("../assets/img/chat_files/def.png");
      }
    },
    onFileGroup(content) {
      let url = this.$baseStoragePath + JSON.parse(content).file;
      window.open(url);
    },
    showImg(url) {
      this.$hevueImgPreview(url);
    },
    pageChange(page) {
      this.currentPage = page;
      this.allGroupMembers = [];
      this.showGroupMembers();
    },
    pageChangeSmartSearch(page) {
      this.currentPage = page;
      this.groupMemberList = [];
      this.smartSearch();
    },
    onUserAddListCheckbox(item) {
      if (item.selected === undefined) {
        item.selected = true;
      }
      console.log(item.selected);
      if (item.selected) {
        //add user
        console.log("add user");
        this.addUserToGroup(item.account);
      } else {
        //remove user
        this.removeUserToGroup(item.account);
      }
    },
    addUserToGroup(account, allSelected = 0, agentId = 0, keyWord = "") {
      let that = this;
      that
        .$http({
          url: "/api/group/member/add/" + this.selectedGroup.id,
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {
            members: account,
            allSelected: allSelected,
            agentId: agentId,
            keyWord: keyWord,
          },
        })
        .then((res) => {
          let data = res.data;
          // if (data.code == 200) {
          //   this.$bvModal.msgBoxOk("成功添加", {
          //     title: "成功",
          //     size: "sm",
          //     buttonSize: "sm",
          // okVariant: "primary",
          // okTitle: '确认',
          //     headerClass: "p-2 border-bottom-0",
          //     footerClass: "p-2 border-top-0",
          //     centered: true,
          //   });
          // }
        });
    },
    removeUserToGroup(account) {
      let that = this;
      that
        .$http({
          url: "/api/group/member/removeNew/" + this.selectedGroup.id,
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {
            member: account,
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            //TODO add success and error messages
            // this.$bvModal.msgBoxOk("成功移除", {
            //   title: "成功",
            //   size: "sm",
            //   buttonSize: "sm",
            //   okVariant: "primary",
            //   okTitle: '确认',
            //   headerClass: "p-2 border-bottom-0",
            //   footerClass: "p-2 border-top-0",
            //   centered: true,
            // });
          }
        });
    },
    sendMessageGroup(format = 0, content = null) {
      switch (format) {
        case 0:
          this.sendMessageApiGroup(content, format);
          break;
        case 1:
          this.sendMessageApiGroup(JSON.stringify(content), format);
          break;
        case 3:
          this.sendMessageApiGroup(JSON.stringify(content), format);
          break;
        case 8:
          this.sendMessageApiGroup(JSON.stringify(content), format);
          break;
        default:
          break;
      }
      this.inputMessage = "";
    },
    sendMessageApiGroup(content, format) {
      let that = this;
      if (content === " " || content.length <= 1) {
        this.emptyMessageError = true;
        return;
      }

      let data = {
        content: content,
        action: 0,
        format: format,
      };
      this.isShowLoader = true;
      that
        .$http({
          url: "api/message/group/send/" + that.selectedGroup.id,
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: data,
        })
        .then((res) => {
          this.isShowLoader = false;
          if (res.data.code == 200) {
            that.groupMessageContent = "";
            this.$bvModal.msgBoxOk("成功发送信息", {
              title: "成功",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
          }
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    chooseImageGroup: function () {
      document.getElementById("fileUploadImageGroup").click();
    },
    onImageUploadGroup(e) {
      this.uploadFileGroup(e.target.files[0], 3);
    },
    uploadFileGroup(file, format) {
      let that = this;
      let formData = new FormData();
      formData.append("file", file);
      // Toast.loading();
      this.isShowLoader = true;
      that
        .$http({
          url: "/web/file",
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
        .then((res) => {
          this.isShowLoader = false;
          var data = res.data;
          if (data.code == 200) {
            that.sendMessageGroup(1, data.data);
          } else {
            this.isShowLoader = false;
          }
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    // Add all members to the group
    selectAllMembers() {
      this.selectAllModal = true;
    },
    // Remove all group members
    removeAllGroupMembers() {
      let that = this;
      that
        .$http({
          url: "/api/group/member/removeAll/" + this.selectedGroup.id,
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            that.showGroupMembers();
            this.$bvModal.msgBoxOk("成功移除", {
              title: "成功",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
            this.$bvModal.hide("modalRemoveAll");
          }
        });
    },
    chooseVideoGroup: function () {
      document.getElementById("fileUploadVideoGroup").click();
    },
    onVideoUploadGroup(e) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.uploadApiFileGroup(formData, 8);
    },
    uploadApiFileGroup(formData, format) {
      let that = this;
      this.isShowLoader = true;
      that
        .$http({
          url: "/api/file/file",
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
        .then((res) => {
          this.isShowLoader = false;
          var data = res.data;
          var content = {};
          if (data.code == 200) {
            switch (format) {
              case 8:
                content = {
                  duration: 2,
                  image: "73859a7c9b404f16b4c818b0d2983a9c.jpg",
                  mode: 1,
                  size: "5",
                  video: data.data.file,
                };
                that.sendMessageGroup(8, content);
                break;
              case 3:
                content = {
                  file: data.data.file,
                  name: data.data.original,
                  size: "24280",
                };
                that.sendMessageGroup(3, content);
                break;
              default:
                break;
            }
          } else {
            this.isShowLoader = false;
          }
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    chooseFileGroup: function () {
      document.getElementById("fileUploadFileGroup").click();
    },
    onFileUploadGroup(e) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.uploadApiFileGroup(formData, 3);
    },
    onMemberListSearch() {
      let that = this;
      this.isShowLoader = true;
      that
        .$http({
          url:
            "/api/group/member/list/" +
            this.selectedGroup.id +
            "/" +
            (that.currentPage - 1), //current page minus 1 because framework paginate expecting first page to be 1, not 0
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {
            keyword: that.memberListQuery,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.isShowLoader = false;
            that.allGroupMembers = res.data.dataList;
            that.allGroupMembers.forEach((i) => {
              i.selected = true;
            });
            // that.showGroupMembersModal = true;
            that.total_rows = res.data.page.count;
          } else {
            this.isShowLoader = false;
          }
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    processContent(content) {
      let that = this;
      content = content.replace(/face\[([^\s[\]]+?)\]/g, function (face) {
        //转义表情
        var alt = face.replace(/^face/g, "");
        var imgPath = that.emojiIcons.find((o) => alt === o.key);
        return (
          '<img alt="' +
          alt +
          '" title="' +
          alt +
          '" src="' +
          imgPath.text +
          '">'
        );
      });
      return content;
    },
  },
  watch: {
    selectAll: function (val) {
      if (val) {
        this.addMemeberToGroup();
        //TODO when click select all button handle function
      }
    },
  },
  mounted: function () {
    this.groupMemberList = [];
    this.loadAllGroups();
    // this.getAgentList();
  },
};
</script>

<style scoped>
.chatWindowBody {
  height: auto;
  min-height: calc(100% - 104px);
  max-height: calc(100% - 104px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.chatWindowBody::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.chatWindowBody ul li {
  list-style: none;
}

.chatWindowBodyHistory ul li {
  list-style: none;
}

.pannelBackground {
  background: url(../assets/img/pattern.png) repeat,
    linear-gradient(#e1e4e7, #f3f4f5);
  height: 100%;
}

.chatGroupname {
  text-align: left;
  margin: 0;
  line-height: 50px;
  color: #ffffff;
  font-weight: bold;
}

.talktext {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
  background-color: #435f7a;
  padding: 10px 15px;
  border-radius: 20px;
  color: #ffffff;
}

.talktext p {
  margin: 0px;
  max-width: 600px;
  text-align: justify;
}

.sent {
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  display: inline-block;
  text-align: right;
}

.sent .textStyle {
  text-align: right;
}

.sent .fileName {
  text-align: right;
}

.imageright {
  width: 30px;
  border-radius: 50%;
  float: right;
  margin: 6px 0 0 8px;
  height: 30px;
}

.message_input {
  height: 50px;
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  z-index: 99;
}

.emojiDesign {
  background-color: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  margin: 0;
  padding: 7px 14px;
  height: 150px;
  width: 234px;
}

.emojiListDesign {
  cursor: pointer;
  float: left;
  border: 1px solid #e8e8e8;
  overflow: hidden;
  list-style: none;
  padding: 5px;
}

.popoverEmoji .popover-body {
  padding: 0px;
}

.chatHistory {
  text-align: center;
}
</style>
